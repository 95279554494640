import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { get_blog, get_blog_by_id } from '../api/ApiAuth';
import Breadcrumbs from '../components/BreadCrumbs';
import { blog_thumbnail } from '../api/Common';

const TermsAndCondition = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    return (
        <>
        <section class="inner-banner p-100">
          <div class="container">
              <div class="row top-row mb-0 align-items-end">
                  <div class="col-md-12 position-relative">
                          <Breadcrumbs />
                      <h2 class="site-title text-start">Witknowlearn Terms and Condition</h2>
                      {/* <p class="mb-0">Interactive, research-based Math and Science worksheets fostering<br /> independent learning with real-world applications and digital flexibility.</p> */}
                      {/* <p class="mb-0">{blog.resource_synopsis}</p> */}
                  </div>
              </div>
          </div>
        </section>
      
      <section class="sheet-cnt pt-5">
          <div class="container">
              <div class="row flex-column-reverse flex-md-row position-relative">
                  <div class="col-md-12 right ">
                      <div class="card inwrp inwrp d-table mx-auto mb-4">
                          {/* <div class="card-body w-50 mx-auto">
                              <img src={blog_thumbnail()+blog.thumbnail} alt="Image" class="w-100 img-fluid" />
                          </div> */}
                      </div>
                      <div class="inwrp">
                          <div className="card no-select">
                              <div className="card-body shadow-none" contenteditable="false" onCopy={(e) => e.preventDefault()}>
                              <div class="card-body text-left shadow-none p-0">
<div class="body-copy mb-2">Witknowlearn is an online portal which provides Teaching resources, E-Learning solutions and Online Counselling for Teachers, Students and Parents.</div>
<div class="body-copy mb-2">These Terms of Service govern your use of our website operated by witknowlearn.</div>
<div class="body-copy mb-2">Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Your agreement with us includes these Terms and our Privacy Policy ("Agreements"). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</div>
<div class="body-copy mb-2">If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at contact@witknowlearn.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</div>
<div class="body-copy mt-4"><b>Communications</b></div>
<div class="body-copy mb-2">By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:contact@witknowlearn.com" class="text-info">contact@witknowlearn.com</a>.</div>
<div class="body-copy mt-4"><b>Registration</b></div>
<div class="body-copy mb-2">To use the services provided on the website and make purchases, it is compulsory to create an account and register which shall collect the following personally identifiable information about you: Name - including first and last name, email address, mobile phone number and other contact details, demographic profile (like your age, gender, address, etc), a username and password. You also have the option of linking to your Google account. You must keep your account and registration details current and correct for communications related to your purchases from the Website.</div>
<div class="body-copy mb-2">You are solely responsible for protecting the confidentiality of your username and password and any activity under the account will be deemed to have been done by you. In the event you provide us with false/inaccurate details or the Company has a reasonable ground to believe that false and inaccurate information has been furnished, we hold the right to permanently suspend your account.</div>
<div class="body-copy mt-4"><b>Eligibility</b></div>
<div class="body-copy mb-2">Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Site.</div>
<div class="body-copy mb-2">However, if you are a minor, i.e. under the age of 18 years and over the age of 13 years, you may use the Site under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. Witknowlearn reserves the right to terminate or refuse your registration, or refuse to permit access to the Site, if it is discovered or brought to its notice that you are a minor.</div>
<div class="body-copy mt-4"><b>Security</b></div>
<div class="body-copy mb-2">Transactions on the Website are secure and protected. Any information entered by the User when transacting on the Website is encrypted to protect the User against unintentional disclosure to third parties. The User's credit and debit card information is not received, stored by or retained by witknowlearn. This information is supplied by the User directly to the relevant payment gateway which is authorized to handle the information provided and is compliant with the regulations and requirements of various banks and institutions and payment franchisees that it is associated with.</div>
<div class="body-copy mt-4"><b>Free Trial Membership</b></div>
<div class="body-copy mb-2">Witknowlearn may, at its sole discretion, offer a Subscription with a free trial for a limited period in which limited service will be offered and resources will be restricted as compared to full package plan. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</div>
<div class="body-copy mt-4"><b>Paid Membership Plan</b></div>
<div class="body-copy mb-2">Currently, we are offering 1-year subscription plans for Teachers, students and parents and the amount varies depending on different grade/grades, subject/subjects and board and the price varies from each person as per their requirements and the customization of the product. We may extend our plan depending upon the circumstances which may vary in charges. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</div>
<div class="body-copy mt-4"><b>Termination</b></div>
<div class="body-copy mb-2">We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</div>
<div class="body-copy mb-2">If you wish to terminate your account, you may simply discontinue using Service.</div>
<div class="body-copy mb-2">All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</div>
<div class="body-copy mt-4"><b>Refunds/Cancellation</b></div>
<div class="body-copy mb-2">Please read the subscription terms and conditions carefully before subscribing to any of the subscription plans as once you have subscribed you cannot change, cancel your subscription plan. Once you subscribe and make the required payment it shall be final and there cannot be any changes or modifications to the same and neither will there be any refund.</div>
<div class="body-copy mt-4"><b>Pricing</b></div>
<div class="body-copy mb-2">Currently, we are offering 1-year subscription plans for Teachers, students and parents and the amount varies depending on different grade/grades, subject/subjects and board and the price varies from each person as per their requirements and the customization of the product. We may extend our plan depending upon the circumstances which may vary in charges. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</div>
<div class="body-copy mt-4"><b>Prohibited Uses</b></div>
<div class="body-copy mb-2">You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</div>
<div class="body-copy mb-2">
<div class="terms-points"><span>0.1.</span> In any way that violates any applicable national or international law or regulation.</div>
<div class="terms-points"><span>0.2.</span> To exploit, harm, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</div>
<div class="terms-points"><span>0.3.</span> To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</div>
<div class="terms-points"><span>0.4.</span> To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</div>
<div class="terms-points"><span>0.5.</span> In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</div>
<div class="terms-points"><span>0.6.</span> To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</div>
</div>
<div class="body-copy mt-4"><b>Additionally, you agree not to</b></div>
<div class="body-copy mb-2">
<div class="terms-points"><span>0.1.</span> Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party's use of Service, including their ability to engage in real-time activities through Service.</div>
<div class="terms-points"><span>0.2.</span> Use any robot, spider, or other automatic devices, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</div>
<div class="terms-points"><span>0.3.</span> Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</div>
<div class="terms-points"><span>0.4.</span> Use any device, software, or routine that interferes with the proper working of Service.</div>
<div class="terms-points"><span>0.5.</span> Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</div>
<div class="terms-points"><span>0.6.</span> Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</div>
<div class="terms-points"><span>0.7.</span> Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</div>
<div class="terms-points"><span>0.8.</span> Take any action that may damage or falsify Company rating.</div>
<div class="terms-points"><span>0.9.</span> Otherwise, attempt to interfere with the proper working of Service.</div>
</div>
<div class="body-copy mt-4"><b>Analytics</b></div>
<div class="body-copy mb-2">We may use third-party Service Providers to monitor and analyze the use of our Service.</div>
<div class="body-copy mt-4"><b>Intellectual Property</b></div>
<div class="body-copy mb-2">Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of witknowlearn and its licensors. Service is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of witknowlearn. Our products shall not be imitated, modified, photoshopped or cropped for personal use without prior consent.</div>
<div class="body-copy mt-4"><b>Copyright Policy</b></div>
<div class="body-copy mb-2">We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights ("Infringement") of any person or entity.</div>
<div class="body-copy mb-2">If you are a copyright owner or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to contact@witknowlearn.com, with the subject line: "Copyright Infringement" and include in your claim a detailed description of the alleged Infringement as detailed below, under "DMCA Notice and Procedure for Copyright Infringement Claims"</div>
<div class="body-copy mb-2">You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</div>
<div class="body-copy mt-4"><b>Changes to Service</b></div>
<div class="body-copy mb-2">We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</div>
<div class="body-copy mt-4"><b>Amendments to Terms</b></div>
<div class="body-copy mb-2">We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</div>
<div class="body-copy mb-2">Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</div>
<div class="body-copy mb-2">By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</div>
<div class="body-copy mt-4"><b>Error Reporting and Feedback</b></div>
<div class="body-copy mb-2">You may provide us either directly at contact@witknowlearn.com or via third-party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service ("Feedback"). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other rights, title or interest in or to the Feedback; (ii) Company may have developed ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party, and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose</div>
<div class="body-copy mt-4"><b>Acknowledgement</b></div>
<div class="body-copy mb-2">BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</div>
<div class="body-copy mt-4"><b>Contact Us</b></div>
<div class="body-copy mb-2">Please send your feedback, comments, and requests for technical support by email: <a href="mailto:contact@witknowlearn.com" class="text-info">contact@witknowlearn.com</a>.</div>
</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      </>
    );
};

export default TermsAndCondition;
