import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ModalLogin from './ModalLogin';
import ModalRegister from './ModalRegister';
import { get_menu_category, get_profile, logout } from '../api/ApiAuth';
import { useNavigate } from 'react-router-dom';
import ModalForgotPassword from './ModalForgotPassword';
const Header = ({profile, setProfile}) => {
    const redirect = useNavigate();
    const location = useLocation();
    const [activeMenus, setActiveMenus] = useState({});

    const toggleMenu = id => {
        setActiveMenus(prev => ({
        ...prev,
        [id]: !prev[id],
        }));
    }; 

    // useEffect(()=>{
    //     if(!profile){
    //         redirect('/');
    //     }
    // },[profile])

    const [menuCategory, setMenuCategory] = useState([])
    useEffect(() => {
        get_menu_category().then((res)=>{
            setMenuCategory(res.data.data);            
        }).catch((err)=>{
            
        })
    }, [])
      function callLogout(){
        // setProfile(null)
        // clearAllCookies();
        // redirect('/')
        logout().then((res)=>{
            setProfile(null)
            redirect('/')
        }).catch((err)=>{

        })
    }
   useEffect(()=>{
    const toggleMenuElement = document.getElementById('toggle-menu');
    const patty = document.querySelector('.patty');
    if (patty  && patty.classList.contains('active')) {
      setActiveMenus({});
      toggleMenuElement.click();
    }
  }, [location]);
  useEffect(() => {
    const currentUrl = location.pathname; // Use pathname to get the current URL path
    if (currentUrl.includes('/view')) {
      const newUrl = currentUrl.replace('/view', ''); // Remove '/view' from the path
      console.log('Redirecting to:', newUrl); // Log the new URL for debugging
      redirect(newUrl, { replace: true }); // Use navigate to redirect to the new URL
    }
  }, [location]);
  return (
    <header>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-auto left-sec logo-wrp">
                    <Link to="/" class="logo">
                        <img src="/assets/images/logo.svg" alt="Logo" />
                    </Link>
                    <div class="mob-btn d-flex align-items-center gap-3">
                    <div class="auth-buttons d-block d-lg-none mt-0">
                        {!profile ? 
                        <ul class="btn-group gap-3">
                            <li><a href="javascript:void(0)" class="site-btn" data-bs-toggle="modal" data-bs-target="#loginModal" id='loginModalbtn'>Login</a></li>
                        </ul>
                        :<ul class="btn-group gap-3 loggedin">
                        <li>
                            <div className="dropdown">
                                <Link to="/dashboard" class="dropdown-toggle" type="button" id="dropdownMenuButtonOne" aria-haspopup="true" aria-expanded="false">
                                    <img src="/assets/images/profile-icon.png" alt="Profile Icon" style={{width: "40px", height: "40px"}} />
                                </Link>
                                <div class="dropdown-menu px-0 py-2 rounded-3 shadow-sm" aria-labelledby="dropdownMenuButtonOne" style={{display: "none"}}>
                                    <Link to={'/dashboard'}><i class="fa-solid fa-gauge me-2"></i> Dashboard</Link>
                                    {/* <a href="#"><i class="fa-solid fa-gear me-2"></i> Account Settings</a> */}
                                    <a onClick={callLogout} style={{ cursor: 'pointer' }}><i class="fa-solid fa-right-from-bracket me-2" ></i> Logout</a>
                                </div>
                            </div>
                        </li>
                        
                    </ul>}

                    </div>
                    <div class="menu-toggle" id="toggle-menu">
                        <div class="patty"></div>
                    </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-between align-items-center right-sec mob-menu">
                    <nav class="flex-fill d-flex d-xl-block">
                        <ul class="main-menu d-flex justify-content-center gap-4">
                            <li><Link to="/membership">Membership</Link></li>
                            {/* <li>
                                <div class="dropdown">
                                    <a href="#" class="btn p-0 dropdown-toggle work-sheet-menu" type="button" data-bs-toggle="dropdown" aria-expanded="false">Work Sheet</a>
                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            {/* <li>
                                <div class="dropdown">
                                    <Link to="/worksheet" class="btn p-0 dropdown-toggle work-sheet-menu" type="button" data-bs-toggle="dropdown" aria-expanded="false">Worksheet</Link>
                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu">
                                        {menuCategory?.map((mc, key)=>{
                                            return(
                                        <li class="dropdown dropend">
                                            <Link to="/worksheet" class="dropdown-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{mc.name}</Link>
                                            <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby={`multilevelDropdownMenu2${key}`}>
                                                {mc.grades.map((mcg)=>{return(
                                                <li class="dropdown dropend">
                                                    <a href="#" class="dropdown-item" type="button" data-bs-toggle="dropdown" aria-expanded="false">{mcg.name}</a>
                                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                                    <ul class="dropdown-menu">
                                                        {mcg.subjects.map((mcgs)=>{return(
                                                        <li><a class="dropdown-item" href="#">{mcgs.subject_name}</a></li>)})}
                                                    </ul>
                                                </li>
                                                )})}
                                            </ul>
                                        </li>
                                        )})}
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            <li>
                                <div className="dropdown">
                                    <Link
                                        to="/worksheet"
                                        className="btn p-0 dropdown-toggle work-sheet-menu"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Worksheet
                                    </Link>
                                    <button
                                        className="p-0 dropdown-toggle-one"
                                        type="button"
                                        
                                    ></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="/worksheet" class="dropdown-item" >Worksheet</Link></li>
                                        {menuCategory?.map((mc, key) => (
                                            <li key={key} className="dropdown dropend">
                                                <Link
                                                    to={`/worksheet?board=${mc.id}`}
                                                    className="dropdown-item"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {mc.name}
                                                </Link>
                                                {mc.grades && mc.grades.length > 0 && (
                                                    <>
                                                        <button
                                                            className={`p-0 dropdown-toggle-one ${activeMenus[mc.id] ? 'active' : ''}`}
                                                            type="button" onClick={() => toggleMenu(mc.id)}
                                                        ></button>
                                                        <ul className={activeMenus[mc.id] ? 'dropdown-menu max-menu active' : 'dropdown-menu max-menu'} aria-labelledby={`multilevelDropdownMenu2${key}`}>
                                                            {mc.grades.map((mcg, key) => (
                                                                <li key={key} className="dropdown dropend">
                                                                    <Link
                                                                        to={`/worksheet?board=${mc.id}&grade=${mcg.id}`}
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {mcg.name}
                                                                    </Link>
                                                                    {mcg.subjects && mcg.subjects.length > 0 && (
                                                                        <>
                                                                            <button
                                                                                className={`p-0 dropdown-toggle-one ${activeMenus[mcg.id] ? 'active' : ''}`}
                                                                                type="button" onClick={() => toggleMenu(mcg.id)}
                                                                            ></button>
                                                                            <ul className={activeMenus[mcg.id] ? 'dropdown-menu min-menu active' : 'dropdown-menu min-menu'} aria-labelledby={`multilevelDropdownMenu4${key}`}>
                                                                                {mcg.subjects.map((mcgs, key) => (
                                                                                    <li key={key}>
                                                                                        <Link
                                                                                            to={`/worksheet?board=${mc.id}&grade=${mcg.id}&subject=${mcgs.id}`}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            {mcgs.subject_name}
                                                                                        </Link>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <Link
                                        to="/notes"
                                        className="btn p-0 dropdown-toggle work-sheet-menu"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Notes
                                    </Link>
                                    <button
                                        className="p-0 dropdown-toggle-one"
                                        type="button"
                                    ></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="/notes" class="dropdown-item" >Notes</Link></li>

                                        {menuCategory?.map((mc, key) => (
                                            
                                            <li key={key} className="dropdown dropend">
                                                <Link
                                                    to={`/notes?board=${mc.id}`}
                                                    className="dropdown-item"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {mc.name}
                                                </Link>
                                                {mc.grades && mc.grades.length > 0 && (
                                                    <>
                                                        <button
                                                            className={`p-0 dropdown-toggle-one ${activeMenus[mc.id] ? 'active' : ''}`}
                                                            type="button" onClick={() => toggleMenu(mc.id)}
                                                        ></button>
                                                        <ul className={activeMenus[mc.id] ? 'dropdown-menu max-menu active' : 'dropdown-menu max-menu'} aria-labelledby={`multilevelDropdownMenu2${key}`}>
                                                            {mc.grades.map((mcg, key) => (
                                                                <li key={key} className="dropdown dropend">
                                                                    <Link
                                                                        to={`/notes?board=${mc.id}&grade=${mcg.id}`}
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {mcg.name}
                                                                    </Link>
                                                                    {mcg.subjects && mcg.subjects.length > 0 && (
                                                                        <>
                                                                            <button
                                                                                className={`p-0 dropdown-toggle-one ${activeMenus[mcg.id] ? 'active' : ''}`}
                                                                                type="button" onClick={() => toggleMenu(mcg.id)}
                                                                            ></button>
                                                                            <ul className={activeMenus[mcg.id] ? 'dropdown-menu min-menu active' : 'dropdown-menu min-menu'} aria-labelledby={`multilevelDropdownMenu5${key}`}>
                                                                                {mcg.subjects.map((mcgs, key) => (
                                                                                    <li key={key}>
                                                                                        <Link
                                                                                            to={`/notes?board=${mc.id}&grade=${mcg.id}&subject=${mcgs.id}`}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            {mcgs.subject_name}
                                                                                        </Link>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                    </ul>
                                </div>
                            </li>
                            {/* <li>
                                <div class="dropdown">
                                    <a href="#" class="btn p-0 dropdown-toggle work-sheet-menu" type="button" data-bs-toggle="dropdown" aria-expanded="false">Mock Test Series</a>
                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}
                        </ul>
                    </nav>
                    {/* <div class="auth-buttons">
                        <ul class="btn-group gap-3">
                            <li><a href="javascript:void(0)" class="site-btn white-btn"><span>Join Now</span></a></li>
                            <li><a href="javascript:void(0)" class="site-btn">Login</a></li>
                        </ul>
                    </div> */}
                    <div class="auth-buttons">
                        {!profile ? 
                        <ul class="btn-group gap-3">
                            <li><a href="javascript:void(0)" class="site-btn white-btn" data-bs-toggle="modal" data-bs-target="#registerModal"><span>Join Now</span></a></li>
                            <li><a href="javascript:void(0)" class="site-btn d-none d-lg-block" data-bs-toggle="modal" data-bs-target="#loginModal" id='loginModalbtn'>Login</a></li>
                        </ul>
                        :<ul class="btn-group gap-3 loggedin d-none d-lg-block">
                        <li>
                            <div className="dropdown">
                                <Link to="/dashboard" class="dropdown-toggle" type="button" id="dropdownMenuButtonOne" aria-haspopup="true" aria-expanded="false">
                                    <img src="/assets/images/profile-icon.png" alt="Profile Icon" style={{width: "40px", height: "40px"}} />
                                </Link>
                                <div class="dropdown-menu px-0 py-2 rounded-3 shadow-sm" aria-labelledby="dropdownMenuButtonOne" style={{display: "none"}}>
                                    <Link to={'/dashboard'}><i class="fa-solid fa-gauge me-2"></i> Dashboard</Link>
                                    {/* <a href="#"><i class="fa-solid fa-gear me-2"></i> Account Settings</a> */}
                                    <a onClick={callLogout} style={{ cursor: 'pointer' }}><i class="fa-solid fa-right-from-bracket me-2"></i> Logout</a>
                                </div>
                            </div>
                        </li>
                        
                    </ul>}

                    </div>
                </div>
            </div>
        </div>
        
        <ModalLogin setProfile={setProfile}/>
        <ModalRegister setProfile={setProfile}/>
        <ModalForgotPassword />
        
    </header>
  )
}

export default Header