import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();

  // Split the pathname into an array, removing empty strings
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Function to check if a value is an integer
  const isInteger = (value) => /^\d+$/.test(value);

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathnames
          .filter((value) => !isInteger(value)) // Skip the integer segments
          .map((value, index) => {
            // Dynamically generate the breadcrumb path
            const to = `/${pathnames
              .slice(0, index + 1)
              .filter((v) => !isInteger(v)) // Skip integers when building the link
              .join('/')}`;

            const isLast = index === pathnames.filter((v) => !isInteger(v)).length - 1;

            return isLast ? (
              // Last breadcrumb item (no link, active state)
              <li key={to} className="breadcrumb-item active" aria-current="page">
                {capitalizeFirstLetter(decodeURIComponent(value).replace(/-/g, ' '))}
              </li>
            ) : (
              // Breadcrumb items with links
              <li key={to} className="breadcrumb-item">
                <Link to={to}>
                  {capitalizeFirstLetter(decodeURIComponent(value).replace(/-/g, ' '))}
                </Link>
              </li>
            );
          })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
