import React from 'react'
import { testimonialPath } from '../api/Common'

const TesimonalCard = ({testimonial}) => {
  return (
    <div class="single-item">
        <div class="inwrp">
            <div class="img-group">
                <img src={testimonialPath()+testimonial.image} alt="Image" style={{maxWidth: 100}}/>
            </div>
            <p>{testimonial.description}</p>
            <div class="author-wrp">
                <p><strong>{testimonial.name}</strong></p>
                <p>{testimonial.title}</p>
            </div>
        </div>
    </div>
  )
}

export default TesimonalCard