import React from 'react'
import { Helmet } from 'react-helmet'

const SeoBlog = () => {
  return (
    <Helmet>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
<meta name="description" content="Read blogs on kid’s learning, teaching and parenting and discover a whole new perspective about education.131" />
<meta name="keywords" content="types of resources, exam anxiety, testing for anxiety, testing for anxiety and depression, anxiety before exam, exam anxiety tips, exam anxiety symptoms, how to reduce exam anxiety, exam anxiety medication, how to calm exam anxiety, how to control exam anxiety, how to relieve exam anxiety, exam anxiety drugs, exam anxiety panic attack, parenting, parenting tips" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<link rel="canonical" href={window.location.href} />
<meta content="en_US" property="og:locale" />
<meta content="Witknowlearn" property="og:site_name" />
<meta content="website" property="og:type" />
<meta content={window.location.href} property="og:url" />
<meta content="Learning, teaching and parenting blogs " property="og:title" />
<meta content="Read blogs on kid’s learning, teaching and parenting and discover a whole new perspective about education." property="og:description" />
<meta property="og:site_name" content="Witknowlearn - A family of learners" />

<title>Learning, teaching and parenting blogs</title>
</Helmet>
  )
}

export default SeoBlog