import React from 'react'
import { Helmet } from 'react-helmet'
import { thumbnail } from '../api/Common';

const SeoView = ({description, keywords, resource_title, image}) => {
  return (
    <Helmet>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="canonical" href={window.location.href} />
    <meta content="en_US" property="og:locale" />
    <meta content="Witknowlearn" property="og:site_name" />
    <meta content="website" property="og:type" />
    <meta content={window.location.href} property="og:url" />
    <meta content={resource_title} property="og:title" />
    <meta content={description} property="og:description" />
    <meta content={thumbnail()+image} property="og:image" />
    <meta property="og:site_name" content="Witknowlearn - A family of learners" />
    <meta content="800" property="og:image:width"/>
    <meta content="650" property="og:image:height"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:site" content="@Witknowlearn" />
    <meta name="twitter:title" content={resource_title} />
    <meta name="twitter:image" content={thumbnail()+image} />
    <meta name="twitter:image:width" content="800" />
    <meta name="twitter:image:height" content="418" />
    <meta name="google-signin-client_id" content="478622947754-v4o0b4it0i2apsqnhsumicdmri29ha6u.apps.googleusercontent.com" />
    <meta name="p:domain_verify" content="453de28fc69e793874e2b41e7f3f5fce" />
    
    <title>{resource_title}</title>

  </Helmet>
  )
}

export default SeoView