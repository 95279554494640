import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import Membership from './pages/Membership';
import Worksheet from './pages/Worksheet';
import View from './pages/View';
import Dashboard from './pages/Dashboard';
import { get_profile, testimonial_list } from './api/ApiAuth';
import BlogList from './pages/BlogList';
import BlogView from './pages/BlogView';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition';
import RefundAndCancellation from './pages/RefundAndCancellation';
import Aboutus from './pages/Aboutus';
import TawkToChat from './components/TawkToChat';

function App() {
  const [profile, setProfile] = useState(null);
  useEffect(()=>{
    get_profile().then((res)=>{
        setProfile(res.data.data)
    }).catch((err)=>{

    })
  },[])
  const [testimonialList, setTestimonialList] = useState([]);
  useEffect(()=>{
    testimonial_list().then((res)=>{
      setTestimonialList(res.data.data);
    }).catch((err)=>{

    })
  },[])  
  return (
    <Router>
      <Header profile={profile} setProfile={setProfile} />
      <Routes>
        <Route path="/" element={<Home testimonialList={testimonialList}/>} />
        <Route path="/membership" element={<Membership setProfile={setProfile} profile={profile}/>} />
        <Route path="/worksheet" element={<Worksheet />} />
        <Route path="/notes" element={<Worksheet />} />
        <Route path="/search" element={<Worksheet />} />
        <Route path="/worksheet/:id/:name" element={<View />} />
        <Route path="/notes/:id/:name" element={<View />} />
        <Route path="/dashboard" element={<Dashboard profile={profile} setProfile={setProfile} />} />
        <Route path='/blog' element={<BlogList />} />
        <Route path='/blog/:id/:name' element={<BlogView />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-condition' element={<TermsAndCondition />} />
        <Route path='/refund-and-cancellation' element={<RefundAndCancellation />} />
        <Route path='/about-us' element={<Aboutus />} />
        <Route path='/contact-us' element={<Contact />} />
      </Routes>
      <TawkToChat />
      <Footer />
    </Router>
  );
}

export default App;
;