import React, { useEffect, useRef, useState } from 'react'
import { blog_list } from '../api/ApiAuth';
import Breadcrumbs from '../components/BreadCrumbs';
import BlogCard from '../components/BlogCard';
import SeoBlog from '../seo/SeoBlog';
import SeoBlogView from '../seo/SeoBlogView';
import { useLocation } from 'react-router-dom';

const BlogList = () => { 
  const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]); 
    const [blogList, setBlogList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const currentPage = useRef(null);

    useEffect(()=>{
      setLoading(true);
      blog_list({page: page}).then((res)=>{
        const last_page = res.data.data.last_page;
        if(currentPage.current <= last_page){
          currentPage.current = res.data.data.current_page;
          console.log(res.data.data.values);
          setBlogList((prevItems) => [...prevItems, ...res.data.data.values]);
          setHasMore(currentPage.current < res.data.data.last_page);
          setLoading(false);
        }
      }).catch((err)=>{

      })
    },[page])
    return (
      <>
      <SeoBlog />
        <section className="inner-banner p-100">
           <div className="container">
             <div className="row top-row align-items-end">
               <div className="col-md-12 position-relative">
                 <Breadcrumbs />
                 <p>
                    Many of us are avid readers. The modern age readers are interested in reading Blogs. What are Blogs? What is the meaning of blogs? Blogs are discussions or informational websites that hold our attention with catchy headlines and easy to follow tips. Such articles are lifesavers on any topic we wish to know more about.
                    Here in this section, we wish to provide you with discrete information on the topics listed below: Maths blogs, Educational blogs Science blogs Career guidance blogs. Teaching blogs Academic blogs And much more exciting and informative stuff. Stay tuned to witknowlearn for the latest updates on our blogs.
                 </p>
               </div>
            </div>
          </div>
        </section>
        <section className="worksheet-lists p-100" >
            <div className="container">
                <div className="row">
                    {blogList?.map((gr, key) => (
                        <BlogCard key={key} data={gr} />
                    ))}
                    {blogList?.length === 0 && !loading && <div>No data found</div>}
                </div>
                {loading && <div>Loading...</div>}
                {!loading && hasMore && (
                    <div className="text-center mt-4">
                        <button 
                            className="btn btn-primary" 
                            onClick={() => setPage((prevPage) => prevPage + 1)} 
                            disabled={loading}
                        >
                            Load More
                        </button>
                    </div>
                )}
            </div>
        </section>
  
      </>
    );
  };
  
  export default BlogList;
  