import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaPinterest, FaYoutube } from 'react-icons/fa';
const Footer = () => {
  return (
    <footer>
        <div class="top-footer">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Link to="/">
                            <img src="/assets/images/logo-footer.svg" alt="Logo" />
                        </Link>
                    </div>
                    <div class="col">
                        <h4>For Students</h4>
                        <ul>
                            <li><Link to="/worksheet">Worksheet</Link></li>
                            <li><Link to="/notes">Notes</Link></li>
                            {/* <li><a href="javascript:void(0)">Practice question paper</a></li> */}
                            {/* <li><a href="javascript:void(0)">Mock test series</a></li> */}
                        </ul>
                        <img src="/assets/images/7.png" alt="" srcset="" style={{width: "50%"}}/>
                    </div>
                    <div class="col">
                        <h4>For Parents</h4>
                        <ul>
                            {/* <li><a href="javascript:void(0)">Learning for child</a></li> */}
                            <li><Link to="/worksheet">Worksheet for child</Link></li>
                            <li><Link to="/notes">Notes for child</Link></li>
                        </ul>
                        <img src="/assets/images/6.png" alt="" srcset="" style={{width: "50%"}}/>
                    </div>
                    <div class="col">
                        <h4>Witknowlearn</h4>
                        <ul>
                            {/* <li><a href="javascript:void(0)">Learning for child</a></li> */}
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-and-condition">Terms & condition</Link></li>
                            <li><Link to="/refund-and-cancellation">Refund & Cancellation</Link></li>
                        </ul>
                    </div>
                    {/* <div class="col-auto">
                        <h4>Get Contact</h4>
                        <ul class="contact-details">
                            <li><strong>Email:</strong> <a href="mailto:contact@witknowlearn.com">contact@witknowlearn.com</a></li>
                            <li><strong>Phone No:</strong> <a href="tel:+917900069140">+91-79000 69140</a></li>
                        </ul>
                        <ul class="social-group d-flex gap-3">
                            
                            <li><a href="https://www.instagram.com/witknowlearn" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} />
                            </a>
                            </li>
                            <li><a href="https://in.pinterest.com/witknowlearn/" target="_blank" rel="noopener noreferrer">
                                <FaPinterest size={30} />
                            </a>
                            </li>
                            <li><a href="https://www.youtube.com/channel/UCIzrI9vot2VC8N7TsaBc_sg/featured" target="_blank" rel="noopener noreferrer">
                                <FaYoutube size={30} />
                            </a>
                            </li>
                            
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
        <div class="bottom-footer text-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 left">
                        <p class="mb-0">2024. All rights reserved by witknowlearn.</p>
                    </div>
                    <div class="col-md-6 right">
                        <ul class="d-flex justify-content-end gap-4">
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-and-condition">Terms & condition</Link></li>
                            {/* <li><a href="javascript:void(0)">Subscription</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer