import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../components/BreadCrumbs';
import { FaInstagram, FaPinterest, FaYoutube } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { contact } from '../api/ApiAuth';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchConfig } from '../api/Common';

const Contact = () => {
  const { pathname } = useLocation();
  const siteKey = recaptchConfig().siteKey; // Replace with your actual site key from Google reCAPTCHA

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, [pathname]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    requirements: ''
  });

  const [captchaValue, setCaptchaValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && isNaN(value)) return;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate form data
  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.email) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Email is invalid";
    if (!formData.phone) tempErrors.phone = "Phone number is required";
    if (!captchaValue) tempErrors.captcha = "Please complete the CAPTCHA";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setSuccessMessage({ for: "sending", message: 'Mail Sending!', color: 'primary' });
      contact(formData).then((res) => {
        setSuccessMessage({ for: "success", message: 'Form submitted successfully!', color: 'success' });
        setFormData({
          name: '',
          email: '',
          phone: '',
          requirements: ''
        });
        setCaptchaValue(null); // Reset the CAPTCHA
      }).catch((err) => {
        setSuccessMessage({ for: "error", message: 'Something went wrong', color: 'danger' });
      });
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value); // Set the CAPTCHA value when the user completes it
  };

  useEffect(() => {
    if (successMessage && (successMessage.for === "success" || successMessage.for === "error")) {
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    }
  }, [successMessage]);

  return (
    <>
      <section class="inner-banner p-100">
        <div class="container">
          <div class="row top-row mb-0 align-items-end">
            <div class="col-md-12 position-relative">
              <Breadcrumbs />
              <h1 class="site-title text-start mb-0"><span>Contact Us</span></h1>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-section p-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 left">
              <div class="inwrp mb-5 mb-md-0">
                <h2 class="site-title text-start"><span>Get in Touch</span> With Us</h2>
                <div class="cntwrp">
                  <div class="row top">
                    <div class="col-auto">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                    <div class="col">
                      <h5 class="fw-bold text-dark">Adddress</h5>
                      <p>103, 1st floor, Gautam Nagar<br /> Ambernath</p>
                    </div>
                  </div>
                  <div class="row bot">
                    <div class="col-auto">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <div class="col">
                      <h5 class="fw-bold text-dark">Phone</h5>
                      <p><a href="tel:+917020069140" class="link-secondary">+91-9403851668</a></p>
                    </div>
                  </div>
                  <div class="row bot">
                    <div class="col-auto">
                      <i class="fa-solid fa-envelope"></i>
                    </div>
                    <div class="col">
                      <h5 class="fw-bold text-dark">Email Address</h5>
                      <p><a href="mailto:contact@witknowlearn.com" class="link-secondary">contact@witknowlearn.com</a></p>
                    </div>
                  </div>
                  <div class="row bot">
                    <div class="col-auto">
                      <i class="fa-solid fa-hashtag"></i>
                    </div>
                    <div class="col">
                      <h2 class="site-title text-start">Follow Us</h2>
                      <h5 class="fw-bold text-dark">Follow Us</h5>
                      <ul class="social-group d-flex gap-3 mt-3">
                        <li>
                          <a href="https://www.instagram.com/witknowlearn" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={30} />
                          </a>
                        </li>
                        <li>
                          <a href="https://in.pinterest.com/witknowlearn/" target="_blank" rel="noopener noreferrer">
                            <FaPinterest size={30} />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/channel/UCIzrI9vot2VC8N7TsaBc_sg/featured" target="_blank" rel="noopener noreferrer">
                            <FaYoutube size={30} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 right">
              <div class="inwrp bg-white rounded-3 p-4 p-lg-5">
                {successMessage && (
                  <div className={`alert alert-${successMessage.color} mt-3`}>
                    {successMessage.message}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <small className="text-danger">{errors.name}</small>}
                  </div>

                  <div className="form-group mt-3">
                    <label className="form-label" htmlFor="email">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                  </div>

                  <div className="form-group mt-3">
                    <label className="form-label" htmlFor="phone">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && <small className="text-danger">{errors.phone}</small>}
                  </div>

                  <div className="form-group mt-3">
                    <label className="form-label" htmlFor="requirements">
                      Message
                    </label>
                    <textarea
                      name="requirements"
                      id="requirements"
                      className="form-control"
                      rows="4"
                      value={formData.requirements}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="form-group mt-3">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={onCaptchaChange}
                    />
                    {errors.captcha && <small className="text-danger">{errors.captcha}</small>}
                  </div>

                  <button type="submit" className="btn site-btn d-block rounded-5 mt-4">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
