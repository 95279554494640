import React, { useEffect } from 'react'
import ModalLogin from '../components/ModalLogin';
import ModalRegister from '../components/ModalRegister';
import { Link, useLocation } from 'react-router-dom';

const Membership = ({setProfile, profile}) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    const breadcrumbStyle = {
        '--bs-breadcrumb-divider': '>'
    };
  return (
    <>
    <section class="inner-banner p-100">
        <div class="container">
            <div class="row top-row mb-0 align-items-end">
                <div class="col-md-12 position-relative">
                    <div class="breadcrumbs">
                        <nav style={breadcrumbStyle} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Membership</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 class="site-title text-start mb-0"><span>Membership</span></h1>
                </div>
            </div>
        </div>
    </section>
    {/* // <!-- Inner Banner Ends Here --> */}

    {/* // <!-- Membership Starts Here --> */}
    <section class="membership p-100">
        <div class="container">
            <div class="row">
                <div class="col-md-12 title-wrp">
                    <h2 class="site-title">Choose your <span>Membership</span></h2>
                    <p class="text-center">Are you want to <strong>change your life?</strong></p>
                </div>
                <div class="col-md-12 cnt-wrp">
                    {/* <div class="d-flex justify-content-center">
                        <ul class="nav nav-tabs" id="membershipTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="teachers-tab" data-bs-toggle="tab" data-bs-target="#teachers-tab-pane" type="button" role="tab" aria-controls="teachers-tab-pane" aria-selected="true">Teachers</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="students-tab" data-bs-toggle="tab" data-bs-target="#students-tab-pane" type="button" role="tab" aria-controls="students-tab-pane" aria-selected="false">Students & Parents</button>
                            </li>
                        </ul>
                    </div> */}
                    <div class="tab-content" id="membershipContent">
                        <div class="tab-pane fade show active" id="teachers-tab-pane" role="tabpanel" aria-labelledby="teachers-tab" tabindex="0">
                            <div class="row d-flex justify-content-center">
                                {!profile &&
                                <div class="col-md-6 left d-flex justify-content-center">
                                    <div class="card ms-auto h-100 free">
                                        <div class="card-body p-0 h-100">
                                            <h3 class="member-card-head site-title"><span>Free</span></h3>
                                            <ul class="list-unstyled">
                                                <li>Download any <strong>two</strong> resources from Nursery to Grade 12</li>
                                                <li>Customer support</li>
                                            </ul>
                                            {!profile ? 
                                            <a href="javascript:void(0)" class="site-secondary-btn w-100" data-bs-target="#loginModal" data-bs-dismiss="modal" data-bs-toggle="modal"><span>Join Now</span></a>
                                            : <Link to='/dashboard' className="site-secondary-btn w-100" ><span>Join Now </span></Link>}
                                        </div>
                                    </div>
                                </div>}
                                <div class="col-md-6 right d-flex justify-content-center">
                                    <div class="card h-100 premium">
                                        <div class="card-body p-0 h-100">
                                            <h3 class="member-card-head site-title"><span>Premium</span></h3>
                                            <h5 class="text-primary text-center">(Validity - 1 Year)</h5>
                                                <ul class="list-unstyled">
                                                    <li><strong class="text-danger">For Nursery to Grade 5</strong><br />Access to all the worksheets based on the payment.</li>
                                                    <li class="mt-3"><strong class="text-danger">From Grade 6 to 12</strong><br />Access to worksheets & notes including mind map <small class="text-muted">(Notes are not available for language subjects)</small></li>
                                                    <li class="mt-3">24/7 Customer support</li>
                                                </ul>
                                            {!profile ?
                                            <a href="javascript:void(0)" class="site-secondary-btn w-100" data-bs-target="#loginModal" data-bs-dismiss="modal" data-bs-toggle="modal"><span>Join Now</span></a>

                                            : <Link to='/dashboard?membership=true' className="site-secondary-btn w-100" ><span>Join Now </span></Link>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="tab-pane fade" id="students-tab-pane" role="tabpanel" aria-labelledby="students-tab" tabindex="0">
                            <div class="row">
                                <div class="col-md-6 left">
                                    <div class="card ms-auto h-100 free">
                                        <div class="card-body p-0 h-100">
                                            <h3 class="member-card-head site-title"><span>Free</span></h3>
                                            <ul>
                                                <li>Access to Free Worksheets only</li>
                                                <li>Access to Free Notes which includes Mind map, MCQs and Extra Questions & Answers</li>
                                                <li>Access to Free NCERT Solutions for Hindi and English subjects</li>
                                                <li>24/7 Dedicated Support</li>
                                            </ul>
                                            {!profile ?
                                            <a href="javascript:void(0)" class="site-secondary-btn w-100" data-bs-target="#loginModal" data-bs-dismiss="modal" data-bs-toggle="modal"><span>Join Now</span></a>

                                            : <Link to='/dashboard' className="site-secondary-btn w-100" ><span>Join Now </span></Link>}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 right">
                                    <div class="card h-100 premium">
                                        <div class="card-body p-0 h-100">
                                            <h3 class="member-card-head site-title"><span>Premium</span></h3>
                                            <ul>
                                                <li>Access to Free Worksheets only</li>
                                                <li>Access to Free Notes which includes Mind map, MCQs and Extra Questions & Answers</li>
                                                <li>Access to Free NCERT Solutions for Hindi and English subjects</li>
                                                <li>24/7 Dedicated Support</li>
                                            </ul>
                                            {!profile ? 
                                            <a href="javascript:void(0)" class="site-secondary-btn w-100" data-bs-target="#loginModal" data-bs-dismiss="modal" data-bs-toggle="modal"><span>Join Now</span></a>
                                            : <Link to='/dashboard' className="site-secondary-btn w-100" ><span>Join Now </span></Link>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div class="row disclaimer-row">
                        <div class="col-md-12">
                            <h3 class="text-center"><strong>Disclaimer</strong></h3>
                            <ul>
                                <li>
                                    All the premium user will be able to download worksheets / Notes or NCERT Solutions based on his or her payment which he or she has chosen.<br />
                                    <span>Example: If you have made payment for CBSE Grade 4 English, then user will only able to download CBSE GRADE 4 ENGLISH WORKSHEETS ONLY.</span>
                                </li>
                                <li>NCERT Solutions are only available for language subjects from grades 6 to 12 and only.</li>
                                <li>NOTES Which includes ( Notes, Mind map and extra Questions) are available for grade 6 to 12 for all the subjects except language subjects.</li>
                                <li>Notes and NCERT Solutions are only available from Grade 6 to 12.</li>
                                <li>From Nursery to Grade 5 Only worksheets are available.</li>
                                <li>No refund once the amount is paid nor you can change the subjects or grade after payment.</li>
                                <li>Please check the syllabus/worksheets before purchasing.</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <ModalLogin setProfile={setProfile}/>
        <ModalRegister setProfile={setProfile}/>
    </section>
    </>
// <!-- Membership Ends Here -->
  )
}

export default Membership