import React, { useState, useCallback } from 'react'
import { login } from '../api/ApiAuth';
import useRazorpay from "react-razorpay";
import { payment_process } from '../api/ApiProduct';
const ModalPrice = ({showModal, setShowModal, resourceDetails, className}) => {
  const [Razorpay] = useRazorpay();

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: 'rzp_live_5HKG28jPLodHqI', // Replace with your Razorpay key ID
      amount: resourceDetails.price*100, // Amount is in paise (50000 paise = INR 500)
      currency: 'INR',
      name: 'Witknowlearn',
      description: resourceDetails.resource_title,
      image: '/assets/images/logo.svg', // Replace with your logo URL
      handler: function (response) {
        let params = {
          board_id    : resourceDetails.board_id,
          grade_id    : resourceDetails.grade_id,
          subject_id  : resourceDetails.subject_id,
          price       : resourceDetails.price,
          payment_id  :response.razorpay_payment_id
        }
        payment_process(params).then((res)=>{
          setShowModal(false)
        }).catch((err)=>{

        })
        
      },
      prefill: {
        name: '',
        email: '',
        contact: ''
      },
      notes: {
        address: 'Your Address'
      },
      theme: {
        color: '#cfa2e8'
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

    
  return (
    <div className={`card rounded-4 shadow-lg ${className ? className : null}`}>
        <div className="modal-body d-flex flex-column align-items-center px-4 py-4 mx-auto">
            <h2 className="site-title text-center">
                <span>Upgrade to better learning opportunities!</span>
            </h2>
            <div className="payment-section text-center mt-4">
                <button className="btn btn-dark py-2 px-4 mb-4" onClick={displayRazorpay}>Pay ₹ {resourceDetails.price}/-</button>
                <p>Once you make the payment, you can access other material available for 
                    <strong> {resourceDetails.board_name} - {resourceDetails.grade_name} - {resourceDetails.subject_name}</strong>, as well.
                </p>
            </div>
        </div>
    </div>
  )
}

export default ModalPrice