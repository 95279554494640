import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams, useLocation } from 'react-router-dom';
import { get_menu_category } from '../api/ApiAuth';

const SeoWorksheet = ({boardList, gradeList, subjectList, searchParams}) => {
    const location = useLocation();
    const url = location.pathname;
    
    const [Title, setTitle] = useState(null);
    const [menuCategory, setMenuCategory] = useState([])
    useEffect(() => {
        get_menu_category().then((res)=>{
            setMenuCategory(res.data.data);
        }).catch((err)=>{
            
        })
    }, [])
    const [boardName, setBoardName] = useState('')
    const [gradeName, setGradeName] = useState('')
    const [subjectName, setSubjectName] = useState('')
    useEffect(()=>{
      let board = menuCategory.find((bl)=>bl.id == searchParams.get('board'));
      let grade = board?.grades.find((gl)=>gl.id == searchParams.get('grade'));
      let subject = grade?.subjects.find((gl)=>gl.id == searchParams.get('subject'));
      if(location.pathname == '/worksheet' || location.pathname == '/notes'){
        if(searchParams && searchParams.get('board')){
          setBoardName(board?.name);
        }
        else{
          setBoardName('')
        }
        if(searchParams && searchParams.get('grade')){
          setGradeName(grade?.name);
        }
        else{
          setGradeName('')
        }
        if(searchParams && searchParams.get('subject')){
          setSubjectName(subject?.subject_name)
        }
        else{
          setSubjectName('')
        }
      }
    },[location.pathname, searchParams, menuCategory])
   
      const worksheetMeta = {
        title: 'Simple worksheets and printables | Thousands of Printable worksheets',
        description: 'Worksheets and printables to enhance kids knowledge. Download now and watch your kids learn better today.',
        canonical: 'https://witknowlearn.com/worksheet',
        keywords: 'Worksheet, worksheets, kids worksheet, kids worksheets, free worksheet, free worksheets, worksheets kids, worksheet for kid, English lesson plan, lesson plan for English, lesson plan in English, lesson plan of English, lesson plan English, worksheet nursery, worksheets for nursery, worksheets nursery, math worksheet class 1, math worksheet for class 1, math worksheets for class 1, kids educational games, kids games educational, lesson plan science, lesson plans science, lesson plan in science, lesson plan of science, lesson plan in math, lesson plan for maths, maths lesson plans, math lessons plans, lesson plan math, lesson plan of maths, lesson plans for maths, lesson plans maths, lesson planning of maths, maths lesson plan, lesson plan about math, color worksheets'
      }
      const worksheetMeta2 = {
        title: `free worksheet for ${boardName} ${gradeName} ${subjectName}`,
        description: `Worksheets and printables for ${boardName} ${gradeName} ${subjectName} to enhance kids knowledge. Download now and watch your kids learn better today`,
        canonical: 'https://witknowlearn.com/worksheet',
        keywords: 'Worksheet, worksheets, kids worksheet, kids worksheets, free worksheet, free worksheets, worksheets kids, worksheet for kid, English lesson plan, lesson plan for English, lesson plan in English, lesson plan of English, lesson plan English, worksheet nursery, worksheets for nursery, worksheets nursery, math worksheet class 1, math worksheet for class 1, math worksheets for class 1, kids educational games, kids games educational, lesson plan science, lesson plans science, lesson plan in science, lesson plan of science, lesson plan in math, lesson plan for maths, maths lesson plans, math lessons plans, lesson plan math, lesson plan of maths, lesson plans for maths, lesson plans maths, lesson planning of maths, maths lesson plan, lesson plan about math, color worksheets'
      }

      const notesMeta = {
        title: 'notes',
        description: 'Worksheets and printables to enhance kids knowledge. Download now and watch your kids learn better today.',
        canonical: 'https://witknowlearn.com/notes',
        keywords: 'activity sheets for kids, kindy activities, activity kids, math game free, math games for free, learn games, learning kids games, speller game, game number, learning, online activity list, online activity sheet, Free Online worksheets for Kids, Thousands of Printable Activities, Mcq science class 10, Question paper model, Decimals worksheet, Question paper generator online free, Automatic question paper generator, Online exam paper generator software, Science worksheet for class 6, Science worksheet for grade 3, Rational numbers for class 7'
      }
      
      const notesMeta2 = {
        title: `Free Activities for ${boardName} ${gradeName} ${subjectName}`,
        description: `Witknowlearn activities for development include worksheets and printables comprising sensory activities for ${boardName} ${gradeName} ${subjectName}. to boost fine motor skills and activate their mid-brain`,
        canonical: 'https://witknowlearn.com/notes',
        keywords: 'activity sheets for kids, kindy activities, activity kids, math game free, math games for free, learn games, learning kids games, speller game, game number, learning, online activity list, online activity sheet, Free Online worksheets for Kids, Thousands of Printable Activities, Mcq science class 10, Question paper model, Decimals worksheet, Question paper generator online free, Automatic question paper generator, Online exam paper generator software, Science worksheet for class 6, Science worksheet for grade 3, Rational numbers for class 7'
      }
      let currentMeta = {};
      if(location.pathname === "/worksheet"){
        if(boardName || gradeName || subjectName){
          currentMeta = worksheetMeta2;
        }
        else{
          currentMeta = worksheetMeta;
        }
      }
      if(location.pathname === "/notes"){
        if(boardName || gradeName || subjectName){
          currentMeta = notesMeta2;
        }
        else{
          currentMeta = notesMeta;
        }
      }
    // const currentMeta = ;
  return (
    <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content={currentMeta.description} />
        <meta name="keywords" content={currentMeta.keywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={currentMeta.canonical} />
        <meta property="og:title" content={currentMeta.title} />
        <meta property="og:description" content={currentMeta.description} />
        <meta property="og:url" content={currentMeta.canonical} />
        <title>{currentMeta.title}</title>
    </Helmet>
  )
}



export default SeoWorksheet