import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { get_blog, get_blog_by_id } from '../api/ApiAuth';
import Breadcrumbs from '../components/BreadCrumbs';
import { blog_thumbnail } from '../api/Common';

const RefundAndCancellation = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    return (
        <>
        <section class="inner-banner p-100">
          <div class="container">
              <div class="row top-row mb-0 align-items-end">
                  <div class="col-md-12 position-relative">
                          <Breadcrumbs />
                      <h2 class="site-title text-start">Witknowlearn Refund and Cancellation</h2>
                      {/* <p class="mb-0">Interactive, research-based Math and Science worksheets fostering<br /> independent learning with real-world applications and digital flexibility.</p> */}
                      {/* <p class="mb-0">{blog.resource_synopsis}</p> */}
                  </div>
              </div>
          </div>
        </section>
      
      <section class="sheet-cnt pt-5">
          <div class="container">
              <div class="row flex-column-reverse flex-md-row position-relative">
                  <div class="col-md-12 right">
                      <div class="card inwrp inwrp d-table mx-auto mb-4">
                          {/* <div class="card-body w-50 mx-auto">
                              <img src={blog_thumbnail()+blog.thumbnail} alt="Image" class="w-100 img-fluid" />
                          </div> */}
                      </div>
                      <div class="inwrp">
                          <div className="card no-select">
                              <div className="card-body shadow-none" contenteditable="false" onCopy={(e) => e.preventDefault()}>
                              <div class="card-body text-left shadow-none p-0">
<div class="body-copy mb-2">Please read the subscription terms and conditions carefully before subscribing to any of the subscription plans as once you have subscribed you cannot change, cancel your subscription plan. Once you subscribe and make the required payment it shall be final and there cannot be any changes or modifications to the same and neither will there be any refund.</div>
</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      </>
    );
};

export default RefundAndCancellation;
