import React from 'react'
import { Link } from 'react-router-dom'
import { blog_thumbnail, thumbnail } from '../api/Common'

const BlogCard = ({data}) => {
  return (
    <Link to={`/blog/${data.id}/${data.resource_title.replace(/\s+/g, '-')}`} className="col-lg-3 col-md-4">
        <div className="card single-sheet">
            <div className="card-head text-center">
                <img src={blog_thumbnail()+data.thumbnail} alt="Image" className="img-fluid"/>
            </div>
            <div className="card-body p-0 pt-3">
                <div className="title-wrp pt-1 d-flex row gx-2">
                    <div className="col">
                        {/* <h4>{data.grade_name}</h4> */}
                    </div>
                </div>
                <h3>{data.resource_title} </h3>
            </div>
        </div>
    </Link>
  )
}

export default BlogCard