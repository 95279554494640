
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ResourceCard from "../components/ResourceCard";
import Breadcrumbs from "../components/BreadCrumbs";
import { get_board_list, get_grade_list, get_resource, get_subject_list } from '../api/ApiProduct';
import SeoWorksheet from "../seo/SeoWorksheet";

const Worksheet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBoard, setSelectedBoard] = useState(searchParams.get('board') || '');
  const [selectedGrade, setSelectedGrade] = useState(searchParams.get('grade') || '');
  const [selectedSubject, setSelectedSubject] = useState(searchParams.get('subject') || '');
  const [resources, setResources] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get('search') || '');
  const sectionRef = useRef(null);
  const currentPage = useRef(null);
  const pathname = location.pathname;

  const [boardList, setBoardList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const updateUrlParams = (params) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (params.board) {
      newSearchParams.set('board', params.board);
      if(params.grade){
        newSearchParams.set('grade', params.grade);
      }
      else{
        newSearchParams.delete('grade');
      }
    }
    if(params.subject){
      newSearchParams.set('subject', params.subject);
    }
    else{
      newSearchParams.delete('subject');
    }
    if(params.search){
      newSearchParams.set('search', params.search);
    }
    else{
      newSearchParams.delete('search');
    }
    setSearchParams(newSearchParams); // This will update the URL and the searchParams state
  };

  useEffect(() => {
    // Fetch board list
    const fetchBoardList = async () => {
      try {
        const res = await get_board_list();
        setBoardList(res.data.data);
      } catch (error) {
        console.error("Error fetching boards:", error);
      }
    };
  
    // Fetch board list on component mount
    fetchBoardList();
  }, []);

  const fetchGradeList = async () => {
    try {
      const res = await get_grade_list({ board_id: selectedBoard });
      setGradeList(res.data.data);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    if (selectedBoard) {
      fetchGradeList();
    }
  }, [selectedBoard]);

  const fetchSubjectList = async () => {
    if (!selectedGrade) return; // Fetch subjects only if a grade is selected
    try {
      const res = await get_subject_list({ board_id: selectedBoard, grade_id: selectedGrade });
      setSubjectList(res.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    fetchSubjectList();
  }, [selectedGrade]);

  const selectBoard = (e) => {
    const newBoardId = e.target.value;
    setSelectedBoard(newBoardId);
    setSelectedGrade('');
    setSelectedSubject('');
    updateUrlParams({ board: newBoardId });
    setPage(1); // Reset page number when board is changed
    setResources([]);
    currentPage.current = null // Clear previous resources when changing
  };

  const selectGrade = (e) => {
    const newGradeId = e.target.value;
    setSelectedGrade(newGradeId);
    setSelectedSubject('');
    updateUrlParams({ board: selectedBoard, grade: newGradeId });
    setPage(1); // Reset page number when grade is changed
    setResources([]); 
    currentPage.current = null;
  };

  const selectSubject = (e) => {
    const newSubjectId = e.target.value;
    setSelectedSubject(newSubjectId);
    updateUrlParams({ board: selectedBoard, grade: selectedGrade, subject: newSubjectId });
    setPage(1); // Reset page number when subject is changed
    setResources([]); // Clear previous resources when changing
    currentPage.current = null;
  };

  const handleInput = (e) => {
    updateUrlParams({search: e.target.value})
    setSearchKeyword(e.target.value);
    setPage(1); // Reset page number when search keyword is changed
    setResources([]); // Clear previous resources when changing
    currentPage.current = null // Clear previous resources when changing
  };
  useEffect(()=>{
      // setSearchKeyword(null);
      setPage(1);
      setResources([]);
      setDataCount(0);
  },[location.pathname, searchParams])
  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      let params = { limit: 20, page: page};
      // resource_type: pathname === '/worksheet'  1 : 3 
      if(pathname === '/worksheet'){
        params.resource_type = 1;
      }
      if(pathname === '/notes'){
        params.resource_type = 3;
      }

      if (searchKeyword) params.search = searchKeyword;
      if (searchParams.get('board')) params.board_id = searchParams.get('board');
      if (searchParams.get('grade')) params.grade_id = searchParams.get('grade');
      if (searchParams.get('subject')) params.subject_id = searchParams.get('subject');
      if (searchParams.get('search')) params.search = searchParams.get('search');

      get_resource(params)
        .then((res) => {
          const last_page = res.data.data.last_page;
          if (currentPage.current <= last_page) {
            setDataCount((prev) => prev + res.data.data.values.length);
            currentPage.current = res.data.data.current_page;
            setResources((prevItems) => [...prevItems, ...res.data.data.values]);
            setHasMore(currentPage.current < res.data.data.last_page);
          }
        })
        .catch(() => console.error("Error fetching resources"))
        .finally(() => setLoading(false));
    };

    fetchData();
  }, [page, searchKeyword, searchParams, pathname]);

  useEffect(() => {
    setSelectedBoard(searchParams.get('board') || '');
    setSelectedGrade(searchParams.get('grade') || '');
    setSelectedSubject(searchParams.get('subject') || '');
  }, [location.search]);

  return (
    <>
      <SeoWorksheet boardList={boardList} gradeList={gradeList} subjectList={subjectList} searchParams={searchParams} />
      <section className="inner-banner p-100">
         <div className="container">
           <div className="row top-row align-items-end">
             <div className="col-md-7 position-relative">
               <Breadcrumbs />
               <h1 className="site-title text-start">
                 All 
                 {location.pathname == "/worksheet" && <span> Worksheet</span>}
                 {location.pathname == "/notes" && <span> Notes</span>}
               </h1>
               <p>
                 Interactive, research-based Math and Science{" "}
                 {location.pathname === "/worksheet" ? "worksheets" : "notes"} fostering <br />
                 independent learning with real-world applications and digital flexibility.
               </p>
             </div>
             <div className="col-md-5">
               <form className="form-search mt-4 mt-md-0">
                 <div className="input-group">
                   <input
                    className="form-control border-end-0 border"
                    type="search"
                    placeholder={`Search Your ${
                      (location.pathname === "/worksheet" && "Worksheet" || location.pathname === "/notes" && "Notes") || "worksheet and Notes" }`}
                    id="search"
                    value={searchKeyword}
                    onChange={handleInput}
                  />
                  <span className="">
                    <button
                      className="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5"
                      type="button"
                    >
                      <img src="/assets/images/icon-search.svg" alt="Icon" />
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div className="row bot-row">
            <div className="col-md-12">
              <form action="" className="form-filter">
                <div className="row">
                  <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                    <select
                      className="form-control form-select"
                      aria-label="Default select example"
                      onChange={selectBoard}
                      value={selectedBoard}
                    >
                      <option value="">Select Board</option>
                      {boardList?.map((bl, key) => (
                        <option key={key} value={bl.id}>
                          {bl.board_display_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                    <select
                      className="form-control form-select"
                      aria-label="Default select example"
                      onChange={selectGrade}
                      value={selectedGrade}
                    >
                      <option value="">Select Grade</option>
                      {gradeList?.map((gl, key) => (
                        <option key={key} value={gl.id}>
                          {gl.grade_display_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                    <select
                      className="form-control form-select"
                      aria-label="Default select example"
                      onChange={selectSubject}
                      value={selectedSubject}
                    >
                      <option value="">Select Subject</option>
                      {subjectList?.map((sl, key) => (
                        <option key={key} value={sl.id}>
                          {sl.subject_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="worksheet-lists p-100" ref={sectionRef}>
  <div className="container">
    <div className="row">
      {resources?.map((gr, key) => (
        <ResourceCard key={key} data={gr} />
      ))}
      {dataCount === 0 && <div>No data found</div>}
    </div>
    
    {/* Show Loading Spinner or Message */}
    {loading && <div>Loading...</div>}

    {/* "Load More" Button */}
    {!loading && hasMore && (
      <div className="text-center mt-4">
        <button
          className="btn btn-primary"
          onClick={() => setPage((prevPage) => prevPage + 1)}
          disabled={loading}
        >
          Load More
        </button>
      </div>
    )}
  </div>
</section>

    </>
  );
};

export default Worksheet;
