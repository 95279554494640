import React, { useEffect, useState } from 'react'
import { change_password, get_profile, logout } from '../api/ApiAuth'
import { bulk_payment_process, get_board_list, get_downloads, get_grade_list, get_payment_history, get_subject_list, payment_process } from '../api/ApiProduct';
import useRazorpay from 'react-razorpay';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
const Dashboard = ({profile, setProfile}) => {
    const { pathname } = useLocation();
    const { membership } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);

    const redirect = useNavigate();

    // useEffect(()=>{

        //     if(!profile){
    //         redirect('/');
    //     }
    // },[profile])
    const [boardList, setBoardList] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);

    const [boardId, setBoardId] = useState(null);
    const [gradeId, setGradeId] = useState([]);
    const [subjectId, setSubjectId] = useState(null);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    const [downloadList, setDownloadList] = useState([]);
    const [paymentHistory, setPaymentHistory] = useState([])
    
    // useEffect(()=>{
    //     if(!profile){
    //         redirect('/');
    //     }
    // },[profile])
    useEffect(() => {
        get_board_list().then((res) => {
            setBoardList(res.data.data);
            setBoardId(res.data.data[0].id);
        }).catch(() => {

        });
    }, []);    
    function selectBoard(e) {
        setGradeList([]);
        setSubjectList([]);
        const selectedBoardId = e.target.value;
        setBoardId(selectedBoardId);
        setSelectedSubjects([]);
        setTotalPrice(0);
    }
    useEffect(()=>{
        get_grade_list({ board_id: boardId }).then((res) => {
            setGradeList(res.data.data);
        }).catch((err) => {
            // Handle error
        });
    },[boardId])
    function selectGrade(e) {
        const { value, checked } = e.target;
        if (checked) {
            setGradeId((prevSelected) => [...prevSelected, value]);
        } 
        else {
            setGradeId((prevSelected) =>
                prevSelected.filter((grade) => grade !== value)
            );
        }
        setSelectedSubjects((prevSelected) => {
            const subjectsToRemove = prevSelected.filter((subject) => subject.grade_id === parseInt(value));
            const totalPriceReduction = subjectsToRemove.reduce((sum, subject) => sum + subject.price, 0);
            setTotalPrice((prevTotal) => prevTotal - totalPriceReduction);
            return prevSelected.filter((subject) => subject.grade_id !== parseInt(value));
        });
    }
    
    useEffect(()=>{
        if(gradeId.length > 0){
            get_subject_list({ board_id: boardId, grade_id: gradeId }).then((res) => {
                setSubjectList(res.data.data)
            }).catch((err) => {

            });
        }
        else{
            setSubjectList([])
        }
    },[gradeId])

    function selectSubject(e) {
        const { value, checked } = e.target;
        const selectedSubject = subjectList.find(subject => subject.id === parseInt(value));
        if (checked && selectedSubject) {
            setSelectedSubjects((prevSelected) => [...prevSelected, selectedSubject]);
            setTotalPrice((prev)=>prev + selectedSubject.price)
        } 
        else {
            setSelectedSubjects((prevSelected) =>
                prevSelected.filter((subject) => subject.id !== parseInt(value))
            );
            setTotalPrice((prev)=>prev - selectedSubject.price)
        }
    }
    const [Razorpay] = useRazorpay();

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };
    const displayRazorpay = async () => {
        const isScriptLoaded = await loadRazorpayScript();
        if (!isScriptLoaded) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
    
        const options = {
            key: 'rzp_live_5HKG28jPLodHqI', // Replace with your Razorpay key ID
            amount: totalPrice*100, // Amount is in paise (50000 paise = INR 500)
            currency: 'INR',
            name: 'Witknowlearn',
            description: 'Test Transaction',
            image: '/assets/images/logo.svg', // Replace with your logo URL
            handler: function (response) {
                const selectedSubjectsForAPI = selectedSubjects.map((subject) => ({
                    subject_id: subject.id,
                    board_id: subject.board_id,
                    grade_id: subject.grade_id,
                    price: subject.price,
                    payment_id  :response.razorpay_payment_id
                }));
                let params = {data : selectedSubjectsForAPI}
                bulk_payment_process(params).then((res)=>{
                    
                }).catch((err)=>{
        
                })  
            },
            prefill: {
                name: '',
                email: '',
                contact: ''
            },
            notes: {
                address: 'Your Address'
            },
            theme: {
                color: '#cfa2e8'
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    useEffect(()=>{
        get_downloads().then((res)=>{
            setDownloadList(res.data.data);
        }).catch((err)=>{
            redirect('/')
        })
    },[])
    function clearAllCookies() {
        const cookies = document.cookie.split(";");
      
        cookies.forEach((cookie) => {
          const name = cookie.split("=")[0].trim();
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
        });
      }
    function callLogout(){
        // setProfile(null)
        // clearAllCookies();
        // redirect('/')
        logout().then((res)=>{
            setProfile(null)
            redirect('/')
        }).catch((err)=>{

        })
    }
    useEffect(()=>{
        get_payment_history().then((res)=>{
            setPaymentHistory(res.data.data);
        }).catch((err)=>{

        })
    },[])
    const [formData, setFormData] = useState({
        password: '',
        conformPassword: ''
    });
    const [errors, setErrors] = useState({
        password: '',
        conformPassword: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (!formData.password) {
            errors.password = 'Password is required';
            isValid = false;
        }

        if (!formData.conformPassword) {
            errors.conformPassword = 'Confirm Password is required';
            isValid = false;
        } else if (formData.password !== formData.conformPassword) {
            errors.conformPassword = 'Passwords do not match';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            change_password(formData).then((res)=>{
                setFormData({
                    password: '',
                    conformPassword: ''
                });
            }).catch((err)=>{

            })
        }
    };
    const location = useLocation();
    const navigate = useNavigate();
    
    // Manage the current tab in state
    const [activeTab, setActiveTab] = useState('#dashboard-tab');
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const isMembership = params.get('membership') === 'true';
    
        if (isMembership) {
          setActiveTab('#membership-tab');
        }
      }, [location.search]);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    
        // // Optionally, update the URL parameter if needed
        // if (tab === '#membership-tab') {
        //   navigate('?membership=true');
        // } else {
        //   navigate('?membership=false');
        // }
      };
    const isChecked = (id) => selectedSubjects.some(subject => subject.id === id);
    return (
    <>
        <section class="inner-banner py-5">
            <div class="container">
                <div class="row top-row mb-0 align-items-end">
                    <div class="col-md-12 position-relative">
                        {profile && <h1 class="site-title text-center">Welcome, <span>{profile?.people_name}</span></h1>}
                    </div>
                </div>
            </div>
        </section>
    {profile &&
        <section class="worksheet-lists explore-notes p-100 dashboard-view">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="nav flex-column nav-pills me-xxl-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {/* <button class="nav-link active" id="dashboard-tab-button" data-bs-toggle="pill" data-bs-target="#dashboard-tab" type="button" role="tab" aria-controls="dashboard-tab" aria-selected="true"><i class="fa-solid fa-gauge me-2"></i> Dashboard</button>
                            <button class="nav-link" id="membership-tab-button" data-bs-toggle="pill" data-bs-target="#membership-tab" type="button" role="tab" aria-controls="membership-tab" aria-selected="false" tabindex="-1"><i class="fa-solid fa-bookmark me-2"></i> Membership</button>
                            <button class="nav-link" id="history-tab-button" data-bs-toggle="pill" data-bs-target="#history-tab" type="button" role="tab" aria-controls="history-tab" aria-selected="false" tabindex="-1"><i class="fa-solid fa-table-list me-2"></i> History</button>
                            <button class="nav-link" id="profile-tab-button" data-bs-toggle="pill" data-bs-target="#profile-tab" type="button" role="tab" aria-controls="profile-tab" aria-selected="false" tabindex="-1"><i class="fa-solid fa-user me-2"></i> Profile</button>
                            <button class="nav-link" id="downloads-tab-button" data-bs-toggle="pill" data-bs-target="#downloads-tab" type="button" role="tab" aria-controls="downloads-tab" aria-selected="false" tabindex="-1"><i class="fa-solid fa-circle-down me-2"></i> Downloads</button>
                            
                            <button class="nav-link" type="button" onClick={callLogout}><i class="fa-solid fa-right-from-bracket me-2"></i> Logout</button> */}

                            <button
                                className={`nav-link ${activeTab === '#dashboard-tab' ? 'active' : ''}`}
                                id="dashboard-tab-button"
                                data-bs-toggle="pill"
                                data-bs-target="#dashboard-tab"
                                type="button"
                                role="tab"
                                aria-controls="dashboard-tab"
                                aria-selected={activeTab === '#dashboard-tab'}
                                onClick={() => handleTabClick('#dashboard-tab')}
                            >
                                <i className="fa-solid fa-gauge me-2"></i> Dashboard
                            </button>
                            <button
                                className={`nav-link ${activeTab === '#membership-tab' ? 'active' : ''}`}
                                id="membership-tab-button"
                                data-bs-toggle="pill"
                                data-bs-target="#membership-tab"
                                type="button"
                                role="tab"
                                aria-controls="membership-tab"
                                aria-selected={activeTab === '#membership-tab'}
                                onClick={() => handleTabClick('#membership-tab')}
                            >
                                <i className="fa-solid fa-bookmark me-2"></i> Membership
                            </button>
                            <button
                                className={`nav-link ${activeTab === '#history-tab' ? 'active' : ''}`}
                                id="history-tab-button"
                                data-bs-toggle="pill"
                                data-bs-target="#history-tab"
                                type="button"
                                role="tab"
                                aria-controls="history-tab"
                                aria-selected={activeTab === '#history-tab'}
                                onClick={() => handleTabClick('#history-tab')}
                            >
                                <i className="fa-solid fa-table-list me-2"></i> History
                            </button>
                            <button
                                className={`nav-link ${activeTab === '#profile-tab' ? 'active' : ''}`}
                                id="profile-tab-button"
                                data-bs-toggle="pill"
                                data-bs-target="#profile-tab"
                                type="button"
                                role="tab"
                                aria-controls="profile-tab"
                                aria-selected={activeTab === '#profile-tab'}
                                onClick={() => handleTabClick('#profile-tab')}
                            >
                                <i className="fa-solid fa-user me-2"></i> Profile
                            </button>
                            <button
                                className={`nav-link ${activeTab === '#downloads-tab' ? 'active' : ''}`}
                                id="downloads-tab-button"
                                data-bs-toggle="pill"
                                data-bs-target="#downloads-tab"
                                type="button"
                                role="tab"
                                aria-controls="downloads-tab"
                                aria-selected={activeTab === '#downloads-tab'}
                                onClick={() => handleTabClick('#downloads-tab')}
                            >
                                <i className="fa-solid fa-circle-down me-2"></i> Downloads
                            </button>
                            <button class="nav-link" type="button" onClick={callLogout}><i class="fa-solid fa-right-from-bracket me-2"></i> Logout</button> 
                        </div>
                    </div>
                    <div class="col-lg-8 tab-content pt-0" id="v-pills-tabContent">
                        <div class={`tab-pane fade show ${activeTab == "#dashboard-tab" ? 'active' : ""}`} id="dashboard-tab" role="tabpanel" aria-labelledby="dashboard-tab-button" tabindex="0">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <h3 class="text-start text-dark"><strong>Dashboard</strong></h3>
                                </div>
                                <div class="col-md-6">
                                    <div class="card single-sheet text-center px-4 py-5">
                                        <div class="card-head text-center">
                                            <img src="/assets/images/worksheet.svg" alt="Image" class="img-fluid" />
                                        </div>
                                        <div class="card-body p-0 pt-3">
                                            <div class="title-wrp pt-1 d-flex row gx-2">
                                                <div class="col">
                                                    <h4>Worksheet</h4>
                                                </div>
                                            </div>
                                            <p>Printable worksheet of math, science and languages including activity sheets.</p>
                                            <Link to='/worksheet'><div class="btn-wrp"><a href="javascript:void(0)" class="site-secondary-btn"><span>Go to worksheets</span></a></div></Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card single-sheet text-center px-4 py-5">
                                        <div class="card-head text-center">
                                            <img src="/assets/images/activities.svg" alt="Image" class="img-fluid" />
                                        </div>
                                        <div class="card-body p-0 pt-3">
                                            <div class="title-wrp pt-1 d-flex row gx-2">
                                                <div class="col">
                                                    <h4>Notes</h4>
                                                </div>
                                            </div>
                                            <p>Classroom activities and developmental activities to enhance fine motor skills for students with learning difficulties.</p>
                                            <Link to='/notes'>
                                            <div class="btn-wrp"><a href="javascript:void(0)" class="site-secondary-btn"><span>Go to notes</span></a></div></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-pane fade ${activeTab == "#membership-tab" ? 'active show' : ""}`} id="membership-tab" role="tabpanel" aria-labelledby="membership-tab-button" tabindex="0">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <h3 class="text-start text-dark text-capitalize"><strong>Just a step away from a great learning experience</strong></h3>
                                </div>
                                <div class="col-md-12">
                                    <div class="card single-sheet text-center px-4 py-4">
                                        <div class="card-body p-0">
                                            <form action="">
                                                <div class="row mb-5">
                                                    <div class="col-md-4 text-start">
                                                        <h5 class="mb-3 fw-bold text-start">Board:</h5>
                                                        {boardList?.map((bl, key)=>{
                                                            return(
                                                        <div class="form-check" key={key}>
                                                            <input class="form-check-input" type="radio" name="board" id={`board-${bl.id}`} value={bl.id} onChange={(e)=>selectBoard(e)} checked={bl.id == boardId ? true : false}/>
                                                            <label htmlFor={`board-${bl.id}`} class="form-check-label">{bl.board_display_name}</label>
                                                        </div>
                                                        )})}
                                                        {/* <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="board" id="pre-primary" />
                                                            <label class="form-check-label" for="pre-primary">PRE-PRIMARY</label>
                                                        </div> */}
                                                        {/* <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="board" id="maharashtra-board" />
                                                            <label class="form-check-label" for="maharashtra-board">Maharashtra State Board</label>
                                                        </div> */}
                                                    </div>
                                                    <div class="col-md-4 text-start">
                                                        <h5 class="mb-3 fw-bold text-start">Grade:</h5>
                                                        {gradeList?.map((gl, key)=>{return(
                                                        <div class="form-check" key={key}>
                                                            <input class="form-check-input" type="checkbox" name="two-commerce" id={`grade-${key}`} value={gl.id} onChange={(e)=>selectGrade(e)}/>
                                                            <label class="form-check-label" htmlFor={`grade-${key}`}>{gl.grade_display_name}</label>
                                                        </div>
                                                        )})}
                                                        {/* <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="two-science" id="two-science" />
                                                            <label class="form-check-label" for="two-science">12 Science</label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="one-arts" id="one-arts" />
                                                            <label class="form-check-label" for="one-arts">11 Science</label>
                                                        </div> */}
                                                    </div>
                                                    <div class="col-md-4 text-start">
                                                        <h5 class="mb-3 fw-bold text-start">Subject:</h5>
                                                        {subjectList?.map((sl, key)=>{return(
                                                        <div class="form-check" key={key}>
                                                            <input class="form-check-input" type="checkbox" name="hindi-lang" id={`subject-${key}`} value={sl.id} onChange={(e)=>selectSubject(e)} checked={isChecked(sl.id)}/>
                                                            <label class="form-check-label" htmlFor={`subject-${key}`}>{sl.subject_name}</label>
                                                        </div>
                                                        )})}
                                                        {/* <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="english-lang" id="english-lang" />
                                                            <label class="form-check-label" for="english-lang">English</label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="maths-lang" id="maths-lang" />
                                                            <label class="form-check-label" for="maths-lang">Maths</label>
                                                        </div> */}
                                                    </div>
                                                    {/* <div class="col-md-12 mt-4">
                                                        <button type="submit" class="site-btn border-0">Get Price</button>
                                                    </div> */}
                                                </div>
                                            </form>
                                            {selectedSubjects.length > 0 &&
                                            <div class="pricing-table">
                                                <h5 class="mb-3 fw-bold text-center">Pricing</h5>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mb-0">
                                                        <thead class="bg-primary text-white table-primary">
                                                            <tr>
                                                                <th>Board</th>
                                                                <th>Grade</th>
                                                                <th>Subject</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {selectedSubjects?.map((selectedSubject, key)=>{
                                                            return(
                                                            <tr key={key}>
                                                                <td>{boardList.find(board => (board.id == selectedSubject.board_id))?.board_display_name || "Board not found"}</td>
                                                                <td>{gradeList.find(grade => (grade.id == selectedSubject.grade_id))?.grade_display_name || "Board not found"}</td>
                                                                <td>{selectedSubject.subject_name}</td>
                                                                <td><i class="fa fa-inr"></i> {selectedSubject.price} </td>
                                                            </tr>)})}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-6">
                                                        <div class="member-price my-2">Membership price : <i class="fa fa-inr"></i> <span class="price fw-bold">{totalPrice}</span>/-</div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <button type="button" class="site-btn border-0" onClick={displayRazorpay}>Pay <i class="fa fa-inr ms-2"></i> <span class="price">{totalPrice}</span>/-</button>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div class="row">
                                                <div class="col-md-12 text-center mt-5">
                                                    <img src="assets/images/secure-image.png" alt="Image" class="img-fluid" />
                                                    <p class="mt-2"><small><strong>Your customize package:</strong> Select Board, Subject and Grade.  <br/>Click on "Get price"button to get the membership price</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-pane fade ${activeTab == "#history-tab" ? 'active' : ""}`} id="history-tab" role="tabpanel" aria-labelledby="history-tab-button" tabindex="0">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <h3 class="text-start text-dark"><strong>History</strong></h3>
                                </div>
                                <div class="col-md-12">
                                    <div class="card single-sheet text-center px-4 py-5">
                                        <div class="card-body p-0">
                                            <div class="table-responsive">
                                                <table class="table table-bordered mb-0">
                                                    <thead class="bg-primary text-white table-primary">
                                                        <tr>
                                                            <th width="22%">Board</th>
                                                            <th width="17%">Grade</th>
                                                            <th width="16%">Subject</th>
                                                            <th width="15%">Paid</th>
                                                            <th width="15%">Expiry Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentHistory ?.map((ph, key)=>{return(
                                                        <tr key={key}>
                                                            <td>{ph.board_display_name}</td>
                                                            <td>{ph.grade_display_name}</td>
                                                            <td>{ph.subject_name}</td>
                                                            <td>{ph.membership_price}</td>
                                                            {/* <td>{ph.plan_expiry}</td> */}
                                                            <td>{new Date(ph.plan_expiry).toLocaleString('en-IN', {
  timeZone: 'Asia/Kolkata',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})}</td>


                                                        </tr>)})}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-pane fade ${activeTab == "#profile-tab" ? 'active' : ""}`} id="profile-tab" role="tabpanel" aria-labelledby="profile-tab-button" tabindex="0">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <h3 class="text-start text-dark"><strong>Profile</strong></h3>
                                </div>
                                <div class="col-md-12">
                                    <div class="card single-sheet text-center px-4 py-5">
                                        <div class="card-body p-0">
                                            <h5 class="mb-4 fw-bold text-start">Personal Details:</h5>
                                            <div class="table-responsive">
                                                <table class="table mb-0 text-start w-auto">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Name</strong></td>
                                                            <td>{profile.people_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Email</strong></td>
                                                            <td>{profile.people_email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Mobile</strong></td>
                                                            <td>{profile.people_mobile}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td><strong>Board</strong></td>
                                                            <td>Matric</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Subject</strong></td>
                                                            <td>Tamil</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Grade</strong></td>
                                                            <td>1</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <h5 class="mb-4 fw-bold text-start mt-5">Change Password:</h5>
                                            {/* <form action="" method="post">
                                                <div class="form-group mb-4 position-relative">
                                                    <input type="password" class="form-control" id="password" name="password" placeholder="Password" required="" />
                                                </div>
                                                <div class="form-group mb-4 position-relative">
                                                    <input type="password" class="form-control" id="conformPassword" name="conformPassword" placeholder="Conform Password" required="" />
                                                </div>
                                                <button type="submit" class="btn site-btn mx-auto d-block mt-4">Submit</button>
                                            </form> */}
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group mb-4 position-relative">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        name="password"
                                                        placeholder="Password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {errors.password && <div className="text-danger">{errors.password}</div>}
                                                </div>
                                                <div className="form-group mb-4 position-relative">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="conformPassword"
                                                        name="conformPassword"
                                                        placeholder="Confirm Password"
                                                        value={formData.conformPassword}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {errors.conformPassword && <div className="text-danger">{errors.conformPassword}</div>}
                                                </div>
                                                <button type="submit" className="btn site-btn mx-auto d-block mt-4">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-pane fade ${activeTab == "#downloads-tab" ? 'active' : ""}`} id="downloads-tab" role="tabpanel" aria-labelledby="downloads-tab-button" tabindex="0">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <h3 class="text-start text-dark"><strong>Downloads</strong></h3>
                                </div>
                                <div class="col-md-12">
                                    <div class="card single-sheet text-center px-4 py-5">
                                        <div class="card-body p-0">
                                            <div class="table-responsive">
                                                <table class="table table-bordered mb-0">
                                                    <thead class="bg-primary text-white table-primary">
                                                        <tr>
                                                            <th>SNO</th>
                                                            <th>Chapter Name</th>
                                                            <th>Name</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {downloadList?.map((dl, key)=>{return(
                                                        <tr key={key}>
                                                            <td>{key+1}</td>
                                                            <td>{dl.chapter_name}</td>
                                                            <td>{dl.resource_title}</td>
                                                            {/* <td>{dl.created_on}</td> */}
                                                            <td>{new Date(dl.created_on).toLocaleString('en-IN', {
                                                                timeZone: 'Asia/Kolkata',
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                })}
                                                            </td>
                                                        </tr>
                                                        )})}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="tab-pane fade" id="logout-tab" role="tabpanel" aria-labelledby="logout-tab-button" tabindex="0">Logout</div> */}
                    </div>
                </div>
            </div>
        </section> }
    </>
  )
}

export default Dashboard