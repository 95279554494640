import React from 'react'
import { Helmet } from 'react-helmet'

const SeoHome = () => {
  return (
    <Helmet>
    {/* Meta Information */}
    <link rel="canonical" href="https://witknowlearn.com/" />
    <meta http-equiv="origin-trial" content="A/kargTFyk8MR5ueravczef/wIlTkbVk1qXQesp39nV+xNECPdLBVeYffxrM8TmZT6RArWGQVCJ0LRivD7glcAUAAACQeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZzIiLCJleHBpcnkiOjE3NDIzNDIzOTksImlzU3ViZG9tYWluIjp0cnVlLCJpc1RoaXJkUGFydHkiOnRydWV9" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta
      name="description"
      content="India's Top Educational Platform for Students and Educators. Access free worksheets, notes, MCQs, and mind maps to boost confidence and reduce exam fear"
    />
    <meta
      name="keywords"
      content="Worksheet, worksheets, kids worksheet, free worksheet, online learning, kids learning online, free worksheets, education, knowledge, lesson plan"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:site_name" content="Witknowlearn" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://witknowlearn.com/" />
    <meta
      property="og:title"
      content="Educational site for Teacher, Student & Parent"
    />
    <meta
      property="og:description"
      content="Worksheets, test generator, expert guidance & much more."
    />
    <meta
      property="og:image"
      content="/assets/images/witknowlearn-social.jpg"
    />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@Witknowlearn" />
    <meta
      name="twitter:title"
      content="Educational site for Teacher, Student & Parent"
    />
    <meta
      name="twitter:image"
      content="/assets/images/witknowlearn-social.jpg"
    />
    <meta name="google-signin-client_id" content="YOUR_GOOGLE_SIGNIN_CLIENT_ID" />
    <meta name="google-site-verification" content="dle-1knXEUm3rma7CVtMjPzEZq_A1NLpzsKhU4VPw90" />

    {/* Title */}
    <title>Educational site for Teacher, Student & Parent</title>

    {/* Favicon */}
    {/* <link
      rel="icon"
      href="/assets/images/favicon.ico"
      type="image/x-icon"
    />
    <link
      rel="apple-touch-icon"
      type="image/x-icon"
      href="/assets/images/favicon.ico"
    /> */}
  </Helmet>
  )
}

export default SeoHome