import React from 'react'
import { Helmet } from 'react-helmet'
import { blog_thumbnail } from '../api/Common'

const SeoBlogView = (data) => {
    console.log(data);
  return (
    <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
<meta name="description" content={data.data.resource_description} />
<meta name="keywords" content={data.data.keywords} />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<link rel="canonical" href={window.location.href} />
<meta content="en_US" property="og:locale" />
<meta content="Witknowlearn" property="og:site_name" />
<meta content="website" property="og:type" />
<meta content={window.location.href} property="og:url" />
<meta content={data.data.resource_title} property="og:title" />
<meta content={data.data.resource_description} property="og:description" />
<meta content={blog_thumbnail()+data.data.thumbnail} property="og:image" />
<meta property="og:site_name" content="Witknowlearn - A family of learners" />

<title>{data.data.resource_title}</title>
    </Helmet>
  )
}

export default SeoBlogView