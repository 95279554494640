import React, { useEffect } from 'react';

const TawkToChat = () => {
  // useEffect(() => {
  //   // Create a script element
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = 'https://embed.tawk.to/61e0615bf7cf527e84d1ff93/default';
  //   script.charset = 'UTF-8';
  //   script.setAttribute('crossorigin', '*');

  //   // Append the script to the document
  //   document.body.appendChild(script);

  //   // Optional: Cleanup script when component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    // Initialize Tawk.to live chat script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script");
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6722f79b4304e3196adaf6d7/1ibg9ee8g';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      var s0 = document.getElementsByTagName("script")[0];
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);
  return null; // This component doesn't render anything visually
};

export default TawkToChat;
