import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { get_blog, get_blog_by_id } from '../api/ApiAuth';
import Breadcrumbs from '../components/BreadCrumbs';
import { blog_thumbnail } from '../api/Common';
import SeoBlogView from '../seo/SeoBlogView';

const BlogView = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    const { id } = useParams();  // Retrieve the blog ID from the URL
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBlog();
    }, [id]);

    const fetchBlog = async () => {
        try {
            const res = await get_blog({id: id});
            setBlog(res.data.data);  // Assuming the response contains blog data
        } catch (err) {
            setError('Failed to load blog');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    
    return (
        // <div className="blog-view">
        //     <div className="container">
        //         {blog ? (
        //             <>
        //                 <h1>{blog.resource_title}</h1>
        //                 {/* Rendering HTML content securely */}
        //                 <div dangerouslySetInnerHTML={{ __html: blog.resource_titledescription }} />
        //             </>
        //         ) : (
        //             <div>No blog found</div>
        //         )}
        //     </div>
        // </div>
        <>
        {blog&&
        <SeoBlogView data={blog}/>}
        {blog &&
        
        <section class="inner-banner p-100">
          <div class="container">
              <div class="row top-row mb-0 align-items-end">
                  <div class="col-md-12 position-relative">
                          <Breadcrumbs />
                      <h1 class="site-title text-start">{blog.resource_title}</h1>
                      {/* <p class="mb-0">Interactive, research-based Math and Science worksheets fostering<br /> independent learning with real-world applications and digital flexibility.</p> */}
                      <p class="mb-0">{blog.resource_synopsis}</p>
                  </div>
              </div>
          </div>
        </section>}
      {blog &&
      <section class="sheet-cnt p-100">
          <div class="container">
              <div class="row flex-column-reverse flex-md-row position-relative">
                  <div class="col-md-12 right">
                      <div class="card inwrp inwrp d-table mx-auto mb-4">
                          <div class="card-body w-50 mx-auto">
                              <img src={blog_thumbnail()+blog.thumbnail} alt="Image" class="w-100 img-fluid" />
                          </div>
                      </div>
                      <div class="inwrp">
                          <div className="card no-select">
                              <div className="card-body" contenteditable="false" dangerouslySetInnerHTML={{ __html: blog.resource_description }} onCopy={(e) => e.preventDefault()}>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>}
      </>
    );
};

export default BlogView;
