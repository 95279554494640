import axios from './AxiosDefault'; 
import { configuration } from './Common';

export function get_resource(params) {
    return axios.get('/get/resource', {
        params: params
    });
}
export function get_board_list() {
    return axios.get('/get/board/list', {});
}
export function get_grade_list(params) {
    let config = configuration();
    config.params = params;
    return axios.get('/get/grade/list', config);
}
export function get_subject_list(params) {
    let config = configuration();
    config.params = params;
    return axios.get('/get/subject/list', config);
}
export function get_resource_details(params) {
    return axios.get('/get/resource/details', {params:params});
}

export function resource_download(params){
    let config = configuration();
    config.params = params;
    // config.responseType = 'blob';
    return axios.get('/resource/download', config)
}
export function payment_process(params){
    let config = configuration();
    return axios.post('/payment/process', params, config);
}
export function bulk_payment_process(params){
    let config = configuration();
    return axios.post('/bulk/payment/process', params, config);
}
export function get_downloads(){
    let config = configuration();
    return axios.get('/get/downloads', config);
}
export function get_payment_history(){
    let config = configuration();
    return axios.get('/get/payment/history', config);
}
