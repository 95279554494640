export function thumbnail(){
    // return "http://127.0.0.1:8000/storage/uploads/thumbnail/";
    // return "https://bwitknowlearn.glowspotup.com/storage/uploads/thumbnail/";
    // return "https://indigo-rail-608933.hostingersite.com/public/storage/uploads/thumbnail/"
    return "https://admin.witknowlearn.com/public/storage/uploads/thumbnail/"
}
export function blog_thumbnail(){
    // return "http://127.0.0.1:8000/storage/uploads/thumbnail/";
    // return "https://bwitknowlearn.glowspotup.com/storage/uploads/thumbnail/";
    // return "https://indigo-rail-608933.hostingersite.com/public/storage/uploads/blog/"
    return "https://admin.witknowlearn.com/public/storage/uploads/blog/"
}
export function testimonialPath(){
    // return "http://127.0.0.1:8000/storage/uploads/thumbnail/";
    // return "https://bwitknowlearn.glowspotup.com/storage/uploads/testimonial/";
    // return "https://indigo-rail-608933.hostingersite.com/public/storage/uploads/testimonial/"
    return "https://admin.witknowlearn.com/public/storage/uploads/testimonial/"

}
export function configuration(){
    return  {
        // headers: { 'Accept': 'application/json', "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2luZGlnby1yYWlsLTYwODkzMy5ob3N0aW5nZXJzaXRlLmNvbS9hcGkvZ29vZ2xlLWxvZ2luIiwiaWF0IjoxNzI5NDMwMDE2LCJleHAiOjE3MzAyOTQwMTYsIm5iZiI6MTcyOTQzMDAxNiwianRpIjoibWVHOXNiMmhyZkMyczluRiIsInN1YiI6IjExMTM0MyIsInBydiI6ImMzN2UyOTQ1ZWFhNDU3MGIzZWEyNzIwY2YwMjhkMGExOGJhZjk4MjMifQ.zoX3OoF8nqNL6j6XlpesVvCbILgRw_R8pJnkMzYtBe4"}
        headers: { 'Accept': 'application/json'}
     };
}
export function recaptchConfig(){
    return{
        siteKey: "6Le0-2YqAAAAAK0PUMRM6a-M8nutLXDkFNY8raCu",
        secretKey: "6Le0-2YqAAAAALOQ8kIXsRhbeVOlydVb5We-A6XB"
    }
}