import React from 'react'
import { thumbnail } from '../api/Common';
import { Link } from 'react-router-dom';

const DownloadCard = ({data}) => {
  return (
        <div class="feature-box bg-white">
            {data && 
            <div class="imgwrp">
                <img src={thumbnail()+data.thumbnail} alt="Image" />
                {/* <img src="/assets/images/ncert-class-3-maths-chapter-12.jpg" alt="Image" /> */}
                {/* {data.resource_title.toUpperCase()} */}
            </div>
            }
            {/* {data && data.is_paid == 0 &&
            <div class="col-auto text-end">
                <img src="/assets/images/free.png" alt="Image" />
            </div>} */}
            <div class="cntwrp">
                {/* <p>{data.board_name} {data.grade_name} {data.subject_name} {data.chapter_name}<br /> {data.resource_title}</p> */}
                <p class='mb-0'><span class="grade-title">{data.grade_name}</span> {data.resource_title}</p>
                <Link to={`/${data.resource_type == 1 ? 'worksheet' : 'notes'}/${data.id}/${data.resource_title.replace(/\s+/g, '-')}`} 
                class="stretched-link"></Link>

            </div>
        </div>
  )
}

export default DownloadCard